import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import SchoolsMap from '../components/SchoolsMap';

const SchoolsMapPage = () => {
  const {
    contentfulSiteData: { siteName },
    contentfulSchoolsMap: {
      title,
      heading,
      locationListHeading,
      locationList,
      metaDescription,
    },
    allContentfulSchool: { nodes },
  } = useStaticQuery(graphql`
    query SchoolsMapPageQuery {
      contentfulSiteData {
        siteName
      }
      contentfulSchoolsMap {
        title
        heading {
          raw
        }
        locationListHeading
        locationList {
          heading
          schools {
            name
            location
            type
            yearStarted
          }
        }
        locationList2 {
          heading
          schools {
            name
            location
            type
            yearStarted
          }
        }
        metaDescription
      }
      allContentfulSchool {
        nodes {
          name
          address {
            lat
            lon
          }
          type
          location
        }
      }
    }
  `);

  return (
    <Layout title={title} description={metaDescription} siteName={siteName}>
      <main>
        <Banner heading={heading || title} isExtended={true} />
        <SchoolsMap
          locationListHeading={locationListHeading}
          locations={locationList}
          items={nodes}
        />
      </main>
    </Layout>
  );
};

export default SchoolsMapPage;
