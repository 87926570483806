import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  mapColours,
  minBreakpointQuery,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Container } from './ui';
import paperGrid from '../images/paper-tear.png';

const StyledSchoolsMap = styled.section`
  overflow-x: clip;
`;

const StyledMapWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 900px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 65px;
  transform: rotate(1.5deg);

  ${minBreakpointQuery.tiny`
    height: 300px;
  `}

  ${minBreakpointQuery.small`
    height: 400px;
  `}

  ${minBreakpointQuery.smedium`
    height: 500px;
  `}

  ${minBreakpointQuery.medium`
    height: 600px;
  `}

  div:first-child {
    z-index: ${zIndexLayers.second};
  }
`;

const StyledBackground = styled.div`
  display: none;

  ${minBreakpointQuery.tiny`
   
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    position: absolute;
    width: 100%;
    height: calc(100% + 60px);
    background-size: 800px auto;
    background-repeat: no-repeat;
    background-position: 50% bottom;
    background-image: url(${paperGrid});
    z-index: ${zIndexLayers.first};
  
  ${({ isTop }) => {
    if (isTop) {
      return css`
        display: block;
        transform: rotate(178.5deg);
        top: -50px;
        right: -10px;
        ${minBreakpointQuery.mlarge`
          background-size: 700px auto;
          right: -130px;
        `}
      `;
    } else {
      return css`
        transform: rotate(-1.5deg);
        top: 0;
        left: -140px;
        ${minBreakpointQuery.mlarge`
          display:block;
          background-size: 700px auto;
        `}
      `;
    }
  }}
 `}
`;

const StyledLocationPin = styled.div`
  position: relative;
  display: inline-block;
  transform: translate(-50%, -100%);
  text-align: center;
  z-index: ${({ display }) => (display ? 1 : '0')};
  pointer-events: none;
`;

const StyledInfo = styled.div`
  display: ${({ display }) => (display ? 'block' : 'none')};
  background-color: ${standardColours.white};
  width: 200px;
  padding: 16px 32px;
  border-radius: 4px;
  font-weight: ${fontWeights.semibold};
  position: relative;
  top: 10px;
`;

const StyledTraingle = styled.div`
  display: ${({ display }) => (display ? 'block' : 'none')};
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid ${standardColours.white};
  margin: auto;
  position: relative;
  top: 10px;
`;

const StyledPin = styled.div`
  background-color: ${({ colour }) =>
    colour === 'Primary'
      ? brandColours.tertiary
      : colour === 'Secondary'
      ? brandColours.senary
      : brandColours.primary};
  height: 33px;
  width: 33px;
  border-radius: 50%;
  border: solid ${standardColours.white} 3px;
  margin: auto;
  pointer-events: all;
  &:hover {
    z-index: 1;
  }
`;

const StyledTextContainer = styled.div``;

const StyledHeading = styled.h2`
  text-align: center;
  width: fit-content;
  margin: auto;
  ${fontSize(20)};
  background: linear-gradient(
    ${standardColours.transparent} 55%,
    ${brandColours.primary} 55%
  );

  ${minBreakpointQuery.small`
    ${fontSize(24)}
  `}

  ${minBreakpointQuery.medium`
    ${fontSize(28)}
    margin-bottom: 70px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(32)}
  `}
`;

const StyledLocations = styled.div``;

const StyledLocationsInner = styled.div`
  display: grid;
  max-width: 670px;
  margin-top: 30px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
    margin-left: auto;
    margin-right: auto;
    column-gap: 50px;
  `};

  ${minBreakpointQuery.medium`
    column-gap: 80px;
    margin-top: 40px;
  `};

  ${minBreakpointQuery.large`
    column-gap: 110px;
    margin-top: 50px;
  `};
`;

const StyledLocation = styled.article`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledLocationHeading = styled.h3`
  ${fontSize(24)}
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  background: linear-gradient(
    ${standardColours.transparent} 55%,
    ${brandColours.tertiary} 55%
  );

  ${minBreakpointQuery.medium`
    margin-top: 0px;
  `}
`;

const StyledLevel = styled.h4`
  text-decoration: underline;
  font-weight: ${fontWeights.regular};
  text-underline-offset: 5px;
`;

const StyledSchool = styled.div``;

const StyledSchoolHeading = styled.h5`
  ${fontSize(18)}
`;

const StyledText = styled.p`
  margin-top: 10px;
`;

const SchoolsMap = ({ locationListHeading, locations, items }) => {
  const mapProps = {
    zoom: 6,
  };

  const schoolsMapStyles = [
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: `${mapColours.primary}`,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: `${mapColours.secondary}`,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: `${mapColours.tertiary}`,
        },
      ],
    },
    {
      featureType: 'water',
      stylers: [
        {
          color: `${mapColours.secondary}`,
        },
      ],
    },
  ];

  let displayLocations = {};

  locations.schools.map(({ name, type, yearStarted }) => {
    if (displayLocations[type]) {
      return displayLocations[type].push({
        name: name,
        yearStarted: yearStarted,
      });
    } else {
      return (displayLocations[type] = [
        { name: name, yearStarted: yearStarted },
      ]);
    }
  });

  const [activeItem, setActiveItem] = useState(false);

  return (
    <StyledSchoolsMap>
      <Container>
        <StyledMapWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
            defaultCenter={[52.145585, -1.981689]}
            defaultZoom={mapProps.zoom}
            options={{
              styles: schoolsMapStyles,
            }}
          >
            {items.map(({ address: { lat, lon }, name, type, location }, i) => {
              const display = activeItem === i;
              return (
                <StyledLocationPin
                  key={i}
                  lat={lat}
                  lng={lon}
                  display={display}
                >
                  <StyledInfo display={display}>
                    {`${name}`}
                    {location && location}
                  </StyledInfo>
                  <StyledTraingle display={display} />
                  <StyledPin
                    colour={type}
                    onClick={() => setActiveItem(display ? undefined : i)}
                  ></StyledPin>
                </StyledLocationPin>
              );
            })}
          </GoogleMapReact>
          <StyledBackground image={paperGrid} isTop={true} />
          <StyledBackground image={paperGrid} />
        </StyledMapWrapper>
        <StyledTextContainer>
          <StyledHeading>{locationListHeading}</StyledHeading>
          <StyledLocations>
            <StyledLocationHeading>{locations.heading}</StyledLocationHeading>
            <StyledLocationsInner>
              {Object.keys(displayLocations)
                .sort()
                .map((item, id) => (
                  <StyledLocation>
                    <StyledLevel key={id}>{item}</StyledLevel>
                    {displayLocations[item].map(({ name, yearStarted }, id) => (
                      <StyledSchool key={id}>
                        <StyledSchoolHeading>{name}</StyledSchoolHeading>
                        {yearStarted && (
                          <StyledText>{`Year Started: ${yearStarted}`}</StyledText>
                        )}
                      </StyledSchool>
                    ))}
                  </StyledLocation>
                ))}
            </StyledLocationsInner>
          </StyledLocations>
        </StyledTextContainer>
      </Container>
    </StyledSchoolsMap>
  );
};
export default SchoolsMap;
